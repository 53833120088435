import React from 'react';
import styled from 'styled-components';
import { MEDIA_TABLET } from 'react-komponents';
import { ArticlePreview } from '../ArticlePreview/articlePreview.component';

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const ArticleList = ({ articles }) => {
  return (
    <Container>
      {articles.map(({ node }) => (
        <ItemContainer>
          <ArticlePreview
            key={node.frontmatter.slug}
            title={node.frontmatter.title}
            date={new Date(node.frontmatter.date).toLocaleDateString(
              'fr',
              options
            )}
            description={node.excerpt}
            path={node.fields.path}
            thumbnail={node.frontmatter.thumbnail}
          />
        </ItemContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 32px;
  margin-left: 32px;
  @media (max-width: ${MEDIA_TABLET}px) {
    flex-direction: column;
    margin-right: 0;
    margin-left: 0;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: 500px;
`;
